import Vue from 'vue';
import Router from 'vue-router';


const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const Result = () =>
    import ( /* webpackChunkName: "index" */ '@/pages/Result/index');
const TryPage = () =>
    import ( /* webpackChunkName: "index" */ '@/pages/TryPage/index');
const Index = () =>
    import ( /* webpackChunkName: "index" */ '@/pages/index/index');
//const Index2 = () => import(/* webpackChunkName: "index" */ '@/pages/index/index2');

const Billing = () =>
    import ( /* webpackChunkName: "bill" */ '@/pages/billing/index');
const BillingDetail = () =>
    import ( /* webpackChunkName: "bill" */ '@/pages/billing/detail');

const FreightIndex = () =>
    import ( /* webpackChunkName: "freight" */ '@/pages/freight/freight');
const FreightQuote = () =>
    import ( /* webpackChunkName: "freight" */ '@/pages/freight/quote');
const FreightQuoteShare = () =>
    import ( /* webpackChunkName: "freight" */ '@/pages/freight/quote-share');
const FreightQuoteHistory = () =>
    import ( /* webpackChunkName: "freight" */ '@/pages/freight/quote-history');
const FreightQuoteDetail = () =>
    import ( /* webpackChunkName: "freight" */ '@/pages/freight/quote-detail');

const OrderIndex = () =>
    import ( /* webpackChunkName: "order" */ '@/pages/order/index');
const OrderView = () =>
    import ( /* webpackChunkName: "order" */ '@/pages/order/view');
const OrderDetail = () =>
    import ( /* webpackChunkName: "order" */ '@/pages/order/detail');
const OrderForm = () =>
    import ( /* webpackChunkName: "order" */ '@/pages/order/form');
const OrderForm2 = () =>
    import ( /* webpackChunkName: "order" */ '@/pages/order/orderform');
const userCompany = () =>
    import ( /* webpackChunkName: "userCompany" */ '@/pages/setting/company');
const Products = () =>
    import ( /* webpackChunkName: "product" */ '@/pages/products/index');

const QuotesIndex = () =>
    import ( /* webpackChunkName: "quote" */ '@/pages/quotes/index');
const QuotesDetail = () =>
    import ( /* webpackChunkName: "quote" */ '@/pages/quotes/detail');
const QuotesForm = () =>
    import ( /* webpackChunkName: "quote" */ '@/pages/quotes/form');

const ReportsIndex = () =>
    import ( /* webpackChunkName: "report" */ '@/pages/reports/index');
const ReportsForm = () =>
    import ( /* webpackChunkName: "report" */ '@/pages/reports/form');
const ReportsAnalysis = () =>
    import ( /* webpackChunkName: "report-analysis" */ '@/pages/reports/analysis');
const ShipmentList = () =>
    import ( /* webpackChunkName: "report-analysis" */ '@/pages/reports/shipmentlist');

const ShareFreight = () =>
    import ( /* webpackChunkName: "setting" */ '@/pages/setting/share-freight');
const Trial = () =>
    import ( /* webpackChunkName: "setting" */ '@/pages/setting/trial');
//const Notices = () => import(/* webpackChunkName: "setting" */ '@/pages/setting/notices');
const Customer = () =>
    import ( /* webpackChunkName: "setting" */ '@/pages/setting/customer');
//const HelpList = () => import(/* webpackChunkName: "setting" */ '@/pages/setting/help');
const Content = () =>
    import ( /* webpackChunkName: "setting" */ '@/pages/setting/content');
const HelpEditor = () =>
    import ( /* webpackChunkName: "setting" */ '@/pages/setting/helpEditor');
const Member = () =>
    import ( /* webpackChunkName: "setting" */ '@/pages/setting/member');

const Po = () =>
    import ( /* webpackChunkName: "po" */ '@/pages/po/index');
const Po2 = () =>
    import ( /* webpackChunkName: "po2" */ '@/pages/po2/index');
const PoDetail = () =>
    import ( /* webpackChunkName: "po2" */ '@/pages/po2/components/index');
const CreatePo = () =>
    import ( /* webpackChunkName: "po2" */ '@/pages/po2/components/CreatePo');
const PoReportForm = () =>
    import ( /* webpackChunkName: "po2" */ '@/pages/po2/components/ReportForm');

const Help = () =>
    import ( /* webpackChunkName: "help" */ '@/pages/help/help');

const Share = () =>
    import ( /* webpackChunkName: "share" */ '@/pages/share/index');

const PA = () =>
    import ( /* webpackChunkName: "share" */ '@/pages/share/pa');

const ISF = () =>
    import ( /* webpackChunkName: "share" */ '@/pages/share/ISF');

const Arrive = () =>
    import ( /* webpackChunkName: "share" */ '@/pages/share/arrive');

const OrderDocs = () =>
    import ( /* webpackChunkName: "share" */ '@/pages/share/orderDocs');

const Inquire = () =>
    import ( /* webpackChunkName: "share" */ '@/pages/share/inquire');

const Activation = () =>
    import ( /* webpackChunkName: "Activation" */ '@/pages/activation/index');

const NetworkIndex = () =>
    import ( /* webpackChunkName: "network" */ '@/pages/network2/organization/index');
const NetworkMy = () =>
    import ( /* webpackChunkName: "network" */ '@/pages/network2/myNetwork/index');
const NetworkCompanies = () =>
    import ( /* webpackChunkName: "network" */ '@/pages/network2/organization/companies');
const NetworkContacts = () =>
    import ( /* webpackChunkName: "network" */ '@/pages/network2/organization/contacts');
const NetworkLocations = () =>
    import ( /* webpackChunkName: "network" */ '@/pages/network2/organization/locations');
const DetailLocations = () =>
    import ( /* webpackChunkName: "network" */ '@/pages/network2/detail/location');
const DetailContact = () =>
    import ( /* webpackChunkName: "network" */ '@/pages/network2/detail/contact');
const DetailCompany = () =>
    import ( /* webpackChunkName: "network" */ '@/pages/network2/detail/company');

const Layout = () =>
    import ('@/components/Layout/Layout');
const IndexLayout = () =>
    import ('@/components/Layout/IndexLayout');
const NetworkLayout = () =>
    import ('@/pages/network2/components/layout');

const Login = () =>
    import ('@/pages/Login/Login');
const Logincas = () =>
    import ('@/pages/Login/Logincas');
const ErrorPage = () =>
    import ('@/pages/Error/Error');

//import Network from '@/pages/Network/index'; 2.0

// Main AnalyticsPage
const Dashboard = () =>
    import ('@/pages/Dashboard/Dashboard');

Vue.use(Router);

export default new Router({
    base: process.env.VUE_APP_RESOURCE_PREFIX ? process.env.VUE_APP_RESOURCE_PREFIX : './',
    mode: process.env.VUE_APP_ROUTE_MODE ? process.env.VUE_APP_ROUTE_MODE : 'hash',
    routes: [{
            path: '/',
            name: 'Index',
            component: Index
        },
        {
            path: '/',
            component: IndexLayout,
            children: [
                // {
                //   path: '/',
                //   name: 'Index',
                //   component: Index
                // },
                {
                    path: '/share/:id',
                    name: 'Share',
                    component: Share
                },
                {
                    path: '/pa/:no/:id',
                    name: 'PA',
                    component: PA
                },
                {
                    path: '/pa/:id',
                    name: 'PA',
                    component: PA
                },
                {
                    path: '/ISF/:no/:id',
                    name: 'ISF',
                    component: ISF,
                    meta: {
                        title: '首页',
                        content: 'disable'
                    },
                },
                {
                    path: '/ISF/:id',
                    name: 'ISF',
                    component: ISF,
                    meta: {
                        title: '首页',
                        content: 'disable'
                    },
                },
                {
                    path: '/OrderDocs/:no',
                    name: 'OrderDocs',
                    component: OrderDocs,
                },
                {
                    path: '/Arrive/:no',
                    name: 'Arrive',
                    component: Arrive,
                },
                {
                    path: '/Inquire/:id',
                    name: 'Inquire',
                    component: Inquire
                },
            ]
        },
        {
            path: '/activation/:token',
            name: 'Activation',
            component: Activation
        },
        {
            path: '/result',
            name: 'resultPage',
            component: Result
        },
        {
            path: '/trypage',
            name: 'tryPage',
            component: TryPage
        },
        {
            path: '/setting/userconpany',
            name: 'userconpany',
            component: userCompany
        },
        {
            path: '/login',
            name: 'Login',
            component: Login,
        },
        {
            path: '/logincas',
            name: 'Logincas',
            component: Logincas,
        },
        {
            path: '/app',
            name: 'Layout',
            component: Layout,
            meta: {
                requireAuth: true
            },
            children: [{
                    path: 'dashboard',
                    name: 'Dashboard',
                    component: Dashboard,
                    meta: {
                        requireAuth: true,
                        authCode: 1
                    },
                },
                {
                    path: 'po',
                    name: 'Po',
                    component: Po,
                    meta: {
                        requireAuth: true,
                        authCode: 11
                    },
                },
                {
                    path: 'po2',
                    name: 'Po2',
                    component: Po2,
                    meta: {
                        requireAuth: true,
                        authCode: 12
                    },
                },
                {
                    path: 'po2/detail/:id',
                    name: 'PoDetail',
                    component: PoDetail,
                    meta: {
                        requireAuth: true,
                        authCode: 12
                    },
                },
                {
                    path: 'po2/create',
                    name: 'CreatePo',
                    component: CreatePo,
                    meta: {
                        requireAuth: true,
                        authCode: 12
                    },
                },
                {
                    path: 'po2/form/:id',
                    name: 'PoReportForm',
                    component: PoReportForm,
                    meta: {
                        requireAuth: true,
                        authCode: 12
                    },
                },
                {
                    path: 'reports/index',
                    name: 'ReportsIndex',
                    component: ReportsIndex,
                    meta: {
                        requireAuth: true,
                        authCode: 7,
                        keepAlive: true
                    },
                },
                {
                    path: 'reports/form/:id',
                    name: 'ReportsForm',
                    component: ReportsForm,
                    meta: {
                        requireAuth: true,
                        authCode: 7
                    },
                },
                {
                    path: 'reports/analysis',
                    name: 'ReportsAnalysis',
                    component: ReportsAnalysis,
                    meta: {
                        requireAuth: true,
                        authCode: 7
                    },
                },
                {
                    path: 'reports/ShipmentList',
                    name: 'ShipmentList',
                    component: ShipmentList,
                    meta: {
                        requireAuth: true,
                        authCode: 7
                    },
                },
                {
                    path: 'billing',
                    name: 'Billing',
                    component: Billing,
                    meta: {
                        requireAuth: true,
                        authCode: 6,
                        keepAlive: true
                    },
                },
                {
                    path: 'billing/detail/:id',
                    name: 'BillDetail',
                    component: BillingDetail,
                    meta: {
                        requireAuth: true,
                        authCode: 6
                    },
                },
                {
                    path: 'freight',
                    name: 'FreightIndex',
                    component: FreightIndex,
                    meta: {
                        requireAuth: true,
                        authCode: 3,
                    },
                },
                {
                    path: 'freight/quote',
                    name: 'FreightQuote',
                    component: FreightQuote,
                    meta: {
                        requireAuth: true,
                        authCode: 3
                    },
                },
                {
                    path: 'freight/quote/share',
                    name: 'FreightQuoteShare',
                    component: FreightQuoteShare,
                    meta: {
                        requireAuth: true,
                        authCode: 3
                    },
                },
                {
                    path: 'freight/quote/history',
                    name: 'FreightQuoteHistory',
                    component: FreightQuoteHistory,
                    meta: {
                        requireAuth: true,
                        authCode: 3
                    },
                },
                {
                    path: 'freight/quote/:id',
                    name: 'FreightQuoteDetail',
                    component: FreightQuoteDetail,
                    meta: {
                        requireAuth: true,
                        authCode: 3
                    },
                },
                {
                    path: 'order',
                    name: 'Order',
                    component: OrderIndex,
                    meta: {
                        requireAuth: true,
                        authCode: 2,
                    },
                },
                {
                    path: 'order/view',
                    name: 'OrderView',
                    component: OrderView,
                    meta: {
                        requireAuth: true,
                        authCode: 2,
                    },
                },
                // 新建订单已废弃
                {
                    path: 'order/form',
                    name: 'OrderForm',
                    component: OrderForm,
                    meta: {
                        requireAuth: true,
                        authCode: 2
                    },
                },
                {
                    path: 'order/OrderForm',
                    name: 'OrderNewForm',
                    component: OrderForm2,
                    meta: {
                        requireAuth: true,
                        authCode: 2
                    },
                },
                {
                    path: 'order/detail/:id',
                    name: 'OrderDetail',
                    component: OrderDetail,
                    meta: {
                        requireAuth: true,
                        authCode: 2
                    },
                },
                {
                    path: 'products',
                    name: 'Products',
                    component: Products,
                    meta: {
                        requireAuth: true,
                        authCode: 5,
                        keepAlive: true
                    },
                },
                {
                    path: 'quotes',
                    name: 'Quotes',
                    component: QuotesIndex,
                    meta: {
                        requireAuth: true,
                        authCode: 4,
                        keepAlive: true
                    },
                },
                {
                    path: 'quotes/form',
                    name: 'QuotesForm',
                    component: QuotesForm,
                    meta: {
                        requireAuth: true,
                        authCode: 4
                    },
                },
                {
                    path: 'network',
                    name: 'Network',
                    component: NetworkLayout,
                    meta: {
                        requireAuth: true,
                        authCode: 8
                    },
                    children: [{
                            path: 'my',
                            name: 'NetworkMy',
                            component: NetworkMy,
                            meta: {
                                requireAuth: true,
                                authCode: 8,
                                tag: '/my'
                            },
                        },
                        {
                            path: '/',
                            name: 'NetworkOrganization',
                            component: NetworkIndex,
                            meta: {
                                requireAuth: true,
                                authCode: 8,
                                tag: '/'
                            },
                        },
                        {
                            path: 'companies',
                            name: 'NetworkCompanies',
                            component: NetworkCompanies,
                            meta: {
                                requireAuth: true,
                                authCode: 8
                            },
                        },
                        {
                            path: 'contacts',
                            name: 'NetworkContacts',
                            component: NetworkContacts,
                            meta: {
                                requireAuth: true,
                                authCode: 8
                            },
                        },
                        {
                            path: 'locations',
                            name: 'NetworkLocations',
                            component: NetworkLocations,
                            meta: {
                                requireAuth: true,
                                authCode: 8
                            },
                        },
                        {
                            path: 'companies/detail/:id',
                            name: 'DetailCompany',
                            component: DetailCompany,
                            meta: {
                                requireAuth: true,
                                authCode: 8,
                                tag: '/my'
                            },
                        },
                        {
                            path: 'contact/detail/:id',
                            name: 'DetailContact',
                            component: DetailContact,
                            meta: {
                                requireAuth: true,
                                authCode: 8,
                                tag: '/'
                            },
                        },
                        {
                            path: 'location/detail/:id',
                            name: 'DetailLocations',
                            component: DetailLocations,
                            meta: {
                                requireAuth: true,
                                authCode: 8,
                                tag: '/'
                            },
                        }
                    ]
                },
                {
                    path: 'quotes/detail/:id',
                    name: 'QuotesDetail',
                    component: QuotesDetail,
                    meta: {
                        requireAuth: true,
                        authCode: 4
                    },
                },
                {
                    path: 'setting/share/freight',
                    name: 'ShareFreight',
                    component: ShareFreight,
                    meta: {
                        requireAuth: true,
                        authCode: 3
                    },
                },
                {
                    path: 'setting/trial',
                    name: 'Trial',
                    component: Trial,
                    meta: {
                        requireAuth: true,
                        authCode: 9,
                        keepAlive: true
                    },
                },
                /*{
                  path: 'setting/notices',
                  name: 'Notices',
                  component: Notices,
                  meta: { requireAuth: true, authVop: true },
                },*/
                {
                    path: 'setting/member',
                    name: 'Member',
                    component: Member,
                    meta: {
                        requireAuth: true,
                        authCode: 9
                    },
                },
                {
                    path: 'setting/content',
                    name: 'Content',
                    component: Content,
                    meta: {
                        requireAuth: true,
                        authVop: true,
                        roleVop: true
                    },
                },
                /*{
                  path: 'setting/help',
                  name: 'Help Editor',
                  component: HelpList,
                  meta: { requireAuth: true, authVop: true },
                },*/
                {
                    path: 'setting/help/edit',
                    name: 'Help Editor',
                    component: HelpEditor,
                    meta: {
                        requireAuth: true,
                        authCode: 9,
                        authVop: true
                    },
                },
                {
                    path: 'setting/customer',
                    name: 'Customer',
                    component: Customer,
                    meta: {
                        requireAuth: true,
                        authCode: 9,
                        keepAlive: true
                    },
                },
                {
                    path: 'help',
                    name: 'Help',
                    component: Help,
                    meta: {
                        requireAuth: true,
                        authCode: 10
                    },
                },
            ],
        },
        {
            path: '*',
            name: 'Error',
            component: ErrorPage,
        }
    ],
});