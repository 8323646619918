import axios from "@/utils/http"
import qs from 'qs';
import baseApi from "./base"
import fileDownload from "js-file-download"

export function trialList(data) {
    return axios.post(`${baseApi.visibility}/Trial/TrialList`, qs.stringify(data))
}

export function trialSave(data) {
    return axios.post(`${baseApi.visibility}/TrialAdd/TrialSave`, qs.stringify(data))
}

export function dredgeHandle(data) {
    return axios.post(`${baseApi.visibility}/Trial/Dredge_handle`, qs.stringify(data))
}

export function sendInviteEmail(data) {
    return axios.post(`${baseApi.visibility}/Trial/sendInviteEmail`, qs.stringify(data))
}

export function noticesList(data) {
    return axios.post(`${baseApi.visibility}/News/NewsList`, qs.stringify(data))
}

export function saveNotices(data) {
    return axios.post(`${baseApi.visibility}/News/Newsave`, qs.stringify(data))
}

export function delNews(data) {
    return axios.post(`${baseApi.visibility}/News/Newsdel`, qs.stringify(data))
}

//客户相关
export function clientList(data) {
    return axios.post(`${baseApi.visibility}/Management/firmList`, qs.stringify(data))
}
//设置客户端属性
export function setClientAttr(data) {
    return axios.post(`${baseApi.visibility}/Management/setClientAttr`, qs.stringify(data))
}
//设置运价
export function setFilterFreight(data) {
    return axios.post(`${baseApi.visibility}/Management/setFilterFreight`, qs.stringify(data))
}
//获取已设置的航线
export function freightList(data) {
    return axios.post(`${baseApi.visibility}/Management/freightList`, qs.stringify(data))
}

//航线子列表
export function soFreightLine(data) {
    return axios.post(`${baseApi.visibility}/Management/searchLine`, qs.stringify(data))
}
//主航线
export function airMailLine(data) {
    return axios.post(`${baseApi.visibility}/Management/airMailLine`, qs.stringify(data))
}

//会员列表/Management/member
export function memberList(data) {
    return axios.post(`${baseApi.visibility}/network/userList`, qs.stringify(data))
}
//设置主账号
export function setAccount(data) {
    return axios.post(`${baseApi.visibility}/Management/setAccount`, qs.stringify(data))
}

export function getCompanyInfo(data) {
    return axios.post(`${baseApi.visibility}/Management/getCompanyInfo`, qs.stringify(data))
}

export function saveCompanyAuthority(data) {
    return axios.post(`${baseApi.visibility}/Management/saveCompanyAuthority`, qs.stringify(data))
}

export function saleDistribution(data) {
    return axios.post(`${baseApi.visibility}/Trial/saleDistribution`, qs.stringify(data))
}

export function improve_company(data) {
    return axios.post(`${baseApi.visibility}/Trial/improve_company`, qs.stringify(data))
}

export function statistics(data) {
    return axios.post(`${baseApi.visibility}/Index/statistics`, qs.stringify(data))
}
export function newsave(data) {
    return axios.post(`${baseApi.visibility}/News/Newsave`, qs.stringify(data))
}


export function StatisticsExecl(data) {
    return axios.get(`${baseApi.visibility}/Index/StatisticsExecl`, {
        responseType: 'blob',
        params: data
    }).then(res => {
        fileDownload(res, 'MemberAnalysis.xlsx');
    })
}