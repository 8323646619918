import axios from "@/utils/http"
import qs from 'qs';
import baseApi from "./base"
import fileDownload from "js-file-download"

export function orderList(data) {
    return axios.post(`${baseApi.visibility}/order/index`, qs.stringify(data))
}

export function orderDetail(data) {
    return axios.post(`${baseApi.visibility}/order/detail`, qs.stringify(data))
}

export function orderDocList(data) {
    return axios.post(`${baseApi.visibility}/order/getDocs`, qs.stringify(data))
}

export function orderSaveDocs(data) {
    return axios.post(`${baseApi.visibility}/order/saveDocs`, qs.stringify(data))
}

export function orderDelDocs(data) {
    return axios.post(`${baseApi.visibility}/order/delDocs`, qs.stringify(data))
}

export function orderSaveStatus(data) {
    return axios.post(`${baseApi.visibility}/order/saveStatus`, qs.stringify(data))
}

export function orderAdd(data) {
    return axios.post(`${baseApi.visibility}/order/add`, qs.stringify(data))
}

export function orderSave(data) {
    return axios.post(`${baseApi.visibility}/order/orderSave`, qs.stringify(data))
}

export function orderUpdate(data) {
    return axios.post(`${baseApi.visibility}/order/updateOrder`, qs.stringify(data))
}

export function orderShare(data) {
    return axios.post(`${baseApi.visibility}/order/orderShare`, qs.stringify(data))
}

export function searchCustomer(data) {
    return axios.post(`${baseApi.visibility}/order/searchCustomer`, qs.stringify(data))
}

export function getQuoteByMid(data) {
    return axios.post(`${baseApi.visibility}/order/getQuoteByMid`, qs.stringify(data))
}

export function getOrderShareDetail(data) {
    return axios.post(`${baseApi.visibility}/Share/orderSharePage`, qs.stringify(data))
}

export function getOrderPaDetail(data) {
    return axios.post(`${baseApi.visibility}/Share/orderPaPage`, qs.stringify(data))
}

export function getOrderArrivePage(data) {
    return axios.post(`${baseApi.visibility}/Share/orderArrivePage`, qs.stringify(data))
}

export function orderPaDocList(data) {
    return axios.post(`${baseApi.visibility}/Share/getDocs`, qs.stringify(data))
}

export function orderInquirePageApi(data) {
    return axios.post(`${baseApi.visibility}/Share/orderInquirePage`, qs.stringify(data))
}

export function shipVesselTrackListApi(data) {
    return axios.post(`${baseApi.visibility}/order/shipVesselTrackList`, qs.stringify(data))
}

export function getMainStatusListApi(data) {
    return axios.post(`${baseApi.visibility}/order/getMainStatusList`, qs.stringify(data))
}

export function PAdownloadFile(data) {
    return axios.post(`${baseApi.visibility}/Share/PAdownloadFile`, qs.stringify(data))
        // return axios.get(`${baseApi.visibility}/Share/PAdownloadFile`, {
        //     responseType: 'blob',
        //     params:data
        // }).then(res => {
        //     fileDownload(res, data.doc_name);
        // })
}

export function orderDownloadExportExcel(data) {
    return axios.post(`${baseApi.visibility}/order/orderExecl`, qs.stringify(data), {
        responseType: 'blob',
    }).then(res => {
        let day1 = new Date();
        fileDownload(res, 'shipment-Milestone' + day1.getFullYear() + "-" + (day1.getMonth() + 1) + "-" + day1.getDate() + '.xlsx');
    })
}
export function statusEmailSubscribeApi(data) {
    return axios.post(`${baseApi.visibility}/order/statusEmailSubscribe`, qs.stringify(data))
}

export function getDocsEamil(data) {
    return axios.post(`${baseApi.visibility}/Share/getEmailDocs`, qs.stringify(data))
}
// 分享文件邮件
export function sendEmailDocs(data) {
    return axios.post(`${baseApi.visibility}/order/sendEmailDocs`, qs.stringify(data))
}
// 订单分群
export function assignGroup(data) {
    return axios.post(`${baseApi.visibility}/order/assignGroup`, qs.stringify(data))
}
// 是否可以发送邮件
export function orderEmailStatus(data) {
    return axios.post(`${baseApi.visibility}/order/orderEmailStatus`, qs.stringify(data))
}

export function getDrayeasy(data) {
    return axios.post(`${baseApi.visibility}/order/getDrayeasy`, qs.stringify(data))
}
export function syncDrayeasy(data) {
    return axios.post(`${baseApi.visibility}/order/syncDrayeasy`, qs.stringify(data))
}
export function refreshBookingStatus(data) {
    return axios.post(`${baseApi.visibility}/order/refreshBookingStatus`, qs.stringify(data))
}

export function getFreightPriceApi(data) {
    return axios.post(`${baseApi.visibility}/Freight/getFreightPrice`, qs.stringify(data))
}

export function getDrayagedetailApi(data) {
    return axios.post(`${baseApi.visibility}/Freight/getDrayagedetail`, qs.stringify(data))
}


